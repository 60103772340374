import '@elf/coral-button';
import '@elf/elf-theme-halo/dark/coral-button';

(async () => {

  const SERVICE_UUID = 'cycling_power';
  const CHARACTERISTIC_UUID = 'cycling_power_measurement';

  const btn = document.querySelector('coral-button');
  const output: HTMLElement = document.querySelector('#power');
  const outputAvg: HTMLElement = document.querySelector('#average span');
  const outputMax: HTMLElement = document.querySelector('#max span');

  const ftp = 140;

  const getColor = (power) => {
    const v = power / ftp * 100;
    if (v > 120) {
      return 'red';
    }
    if (v > 105) {
      return 'orange';
    }
    if (v > 90) {
      return 'yellow';
    }
    if (v > 75) {
      return 'lime';
    }
    if (v > 55) {
      return 'cyan';
    }
    return 'inherit';
  };

  let myCharacteristic;

  let ts = 0;
  let data = [];
  let avg = 0;
  let max = 0;
  let device;
  let retries = 0;

  const handlePower = (power) => {
    if (power) {
      avg = data.length ? (avg * data.length + power) / (data.length + 1) : power;
      max = Math.max(max, power);
      data.push(power);
    }
    // console.log(Math.round(data.reduce((a, b) => a + b) / data.length));
    output.textContent = power.toFixed(0);
    outputAvg.textContent = avg.toFixed(0);
    outputMax.textContent = max.toFixed(0);
    output.style.color = getColor(power);
  }

  const demo = () => {
    let bases = [60, 90, 120, 150, 250];
    let base = 0;
    let targetBase = 0;
    setInterval(() => {
      targetBase = bases[Math.round(Date.now() / 15000) % bases.length];
      const diff = targetBase - base;
      base = base + diff * 0.2;
      handlePower(Math.round(Math.random() * 2 + base));
    }, 500);
    document.body.setAttribute('connected', '');
  };
  // demo();

  const handleNotifications = (event) => {
    let value = event.target.value;
    let a = value.getUint8(2);
    let b = value.getUint8(3);
    let power = Math.min(a, b) << 8 | Math.max(a, b);
    handlePower(power);
  };

  const onStopButtonClick = async () => {
    if (myCharacteristic) {
      try {
        await myCharacteristic.stopNotifications();
        myCharacteristic.removeEventListener('characteristicvaluechanged', handleNotifications);
      }
      catch (error) {
        console.log(error);
      }
    }
  };

  const reconnect = async (device) => new Promise((resolve) => {
    setTimeout(() => resolve(onStartButtonClick()), 1000 * retries);
  })

  const onConnected = async (device) => {
    document.body.setAttribute('connected', '');
    ts = performance.now();
    avg = 0;
    max = 0;
    data = [];
    console.log(`${device.name} connected`);
  };

  const onDisconnected = async (device) => {
    if (retries < 3) {
      retries++;
      reconnect(device);
    }
    else {
      retries = 0;
      document.body.removeAttribute('connected');
      const active = Math.round((performance.now() - ts) / 1000);
      console.log(`${device.name} disconnected. Active for ${active}s.`);
    }
  };

  const onStartButtonClick = async () => {

    try {
      if (!device) {
        // Requesting Bluetooth Device...
        retries = 0;
        device = await navigator.bluetooth.requestDevice({ filters: [{ services: [SERVICE_UUID] }] });
        device.addEventListener('gattserverdisconnected', event => onDisconnected(event.target));
        console.log(device);
      }

      // Connecting to GATT Server...
      const server = await device.gatt.connect();
      onConnected(device);

      // Getting Service...
      const service = await server.getPrimaryService(SERVICE_UUID);

      // Getting Characteristic...
      myCharacteristic = await service.getCharacteristic(CHARACTERISTIC_UUID);

      await myCharacteristic.startNotifications();

      // Notifications started
      myCharacteristic.addEventListener('characteristicvaluechanged', handleNotifications);
    }
    catch (error) {
      device = null;
      console.log(error);
    }
  };

  document.body.addEventListener('tap', (event) => {
    document.body.hasAttribute('connected') ? onStopButtonClick() : onStartButtonClick();
  });

  let timer;
  const showMouse = () => document.body.removeAttribute('no-mouse');
  const hideMouse = () => document.body.setAttribute('no-mouse', '');
  document.body.addEventListener('mousemove', () => {
    clearTimeout(timer);
    showMouse();
    timer = setTimeout(hideMouse, 3000);
  });

})();
